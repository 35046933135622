var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("page-toolbar", {
        attrs: { title: "Responses" },
        scopedSlots: _vm._u([
          {
            key: "contents",
            fn: function () {
              return [
                _c("download-menu-button", {
                  attrs: { headers: _vm.headers, responses: _vm.responses },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("response-table", {
        attrs: {
          client: _vm.client,
          "project-name": _vm.projectName,
          "template-names": _vm.templateNames,
        },
        on: {
          "response-update": _vm.onResponseUpdate,
          "header-update": _vm.onHeaderUpdate,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }