var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: { "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ attrs, on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        text: "",
                        color: "indigo",
                        disabled: _vm.responses.length == 0,
                      },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-download"),
                  ]),
                  _vm._v(" Download "),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-list",
        { attrs: { dense: "" } },
        [
          _c(
            "v-list-item",
            {
              on: {
                click: function ($event) {
                  return _vm.downloadJSON()
                },
              },
            },
            [_c("v-list-item-title", [_vm._v("All data in JSON")])],
            1
          ),
          _c(
            "v-list-item",
            {
              on: {
                click: function ($event) {
                  return _vm.downloadJSON(true)
                },
              },
            },
            [_c("v-list-item-title", [_vm._v("Selected columns in JSON")])],
            1
          ),
          _c(
            "v-list-item",
            {
              on: {
                click: function ($event) {
                  return _vm.downloadCSV()
                },
              },
            },
            [_c("v-list-item-title", [_vm._v("Selected columns in CSV")])],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }