var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card-title",
        [
          _c("v-select", {
            attrs: {
              "hide-details": "",
              width: "80%",
              items: _vm.templateNames,
              label: "Template name",
              disabled: _vm.templateNames.length == 0,
            },
            model: {
              value: _vm.templateName,
              callback: function ($$v) {
                _vm.templateName = $$v
              },
              expression: "templateName",
            },
          }),
          _c("v-spacer"),
          _c("v-text-field", {
            attrs: {
              "append-icon": "mdi-magnify",
              label: "Search",
              "single-line": "",
              "hide-details": "",
            },
            model: {
              value: _vm.searchStr,
              callback: function ($$v) {
                _vm.searchStr = $$v
              },
              expression: "searchStr",
            },
          }),
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          dense: "",
          "group-by": _vm.groupedHeaderValue ? [_vm.groupedHeaderValue] : [],
          headers: _vm.headers,
          items: _vm.responses,
          "items-per-page": 50,
          "footer-props": { itemsPerPageOptions: [10, 30, 50, 100, 300] },
          search: _vm.searchStr,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "group.header",
              fn: function ({ group, groupBy, isOpen, headers, toggle }) {
                return [
                  _c(
                    "td",
                    {
                      staticClass: "text-start pl-3",
                      staticStyle: { height: "24px" },
                      attrs: { colspan: headers.length },
                    },
                    [
                      _c("div", { staticClass: "d-flex" }, [
                        _c(
                          "div",
                          { staticClass: "mr-6" },
                          [
                            _c("v-icon", {
                              staticClass: "mr-2",
                              attrs: { small: "" },
                              domProps: {
                                innerHTML: _vm._s(
                                  isOpen
                                    ? "mdi-minus-circle-outline"
                                    : "mdi-plus-circle-outline"
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  return toggle()
                                },
                              },
                            }),
                            _vm._v(
                              " " + _vm._s(_vm.headersMap[groupBy[0]]) + ": "
                            ),
                            groupBy[0] === "_created_at"
                              ? _c("span", {
                                  staticClass: "font-weight-bold",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.standardDateTimeFormat(group)
                                    ),
                                  },
                                })
                              : _c("span", {
                                  staticClass: "font-weight-bold",
                                  domProps: { innerHTML: _vm._s(group) },
                                }),
                          ],
                          1
                        ),
                        _c("div"),
                      ]),
                    ]
                  ),
                ]
              },
            },
            {
              key: "top",
              fn: function () {
                return [
                  _vm.allAnswerKeys.length > 0
                    ? _c("v-card-text", { staticClass: "py-0" }, [
                        _c(
                          "div",
                          { staticClass: "text-right" },
                          [
                            _c(
                              "v-menu",
                              {
                                attrs: {
                                  "offset-y": "",
                                  "close-on-content-click": false,
                                  "max-height": "500",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    small: "",
                                                    outlined: "",
                                                    color: "grey darken-2",
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { left: "" } },
                                                [_vm._v("mdi-cog-outline")]
                                              ),
                                              _vm._v("Manage Columns"),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  425537208
                                ),
                              },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "px-2",
                                    attrs: { width: "400" },
                                  },
                                  [
                                    _c(
                                      "v-card-text",
                                      [
                                        _c("menu-title", [
                                          _vm._v("Default Columns Visibility"),
                                        ]),
                                        _c(
                                          "v-list",
                                          _vm._l(
                                            _vm.defaultHeaders,
                                            function (header) {
                                              return _c(
                                                "v-list-item",
                                                {
                                                  key: `default-header-${header.value}`,
                                                  staticClass: "px-0",
                                                },
                                                [
                                                  _c("v-list-item-content", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(header.text) +
                                                        " "
                                                    ),
                                                  ]),
                                                  _c(
                                                    "v-list-item-action",
                                                    [
                                                      _c("v-switch", {
                                                        attrs: {
                                                          inset: "",
                                                          dense: "",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.saveShownColumns,
                                                        },
                                                        model: {
                                                          value: header.shown,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              header,
                                                              "shown",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "header.shown",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                        _c("menu-title", [
                                          _vm._v("Answer Columns Visibility"),
                                        ]),
                                        _c("v-combobox", {
                                          staticClass: "mt-2",
                                          attrs: {
                                            multiple: "",
                                            items: _vm.allAnswerKeys,
                                            label:
                                              "Select or type an answer key",
                                            "deletable-chips": "",
                                            "small-chips": "",
                                          },
                                          model: {
                                            value: _vm.answerKeys,
                                            callback: function ($$v) {
                                              _vm.answerKeys = $$v
                                            },
                                            expression: "answerKeys",
                                          },
                                        }),
                                        _c("menu-title", [
                                          _vm._v("Grouped Column"),
                                        ]),
                                        _c("v-select", {
                                          attrs: {
                                            items: [
                                              { text: "---", value: null },
                                              ..._vm.headers,
                                            ],
                                          },
                                          model: {
                                            value: _vm.groupedHeaderValue,
                                            callback: function ($$v) {
                                              _vm.groupedHeaderValue = $$v
                                            },
                                            expression: "groupedHeaderValue",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "item.work_session_id",
              fn: function ({ item, header }) {
                return [
                  _c(
                    "div",
                    {
                      class: header.truncate ? "text-truncate" : "",
                      on: {
                        click: function ($event) {
                          header.truncate = !header.truncate
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.work_session_id))]
                  ),
                ]
              },
            },
            {
              key: "item.worker_id",
              fn: function ({ item, header }) {
                return [
                  _c(
                    "div",
                    {
                      class: header.truncate ? "text-truncate" : "",
                      on: {
                        click: function ($event) {
                          header.truncate = !header.truncate
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.worker_id))]
                  ),
                ]
              },
            },
            {
              key: "item.nanotask_id",
              fn: function ({ item, header }) {
                return [
                  _c(
                    "div",
                    {
                      class: header.truncate ? "text-truncate" : "",
                      on: {
                        click: function ($event) {
                          header.truncate = !header.truncate
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.nanotask_id))]
                  ),
                ]
              },
            },
            {
              key: "item._created_at",
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(_vm.standardDateTimeFormat(item._created_at)) +
                      " "
                  ),
                ]
              },
            },
            _vm._l(_vm.slotNamesForAnswerKeys, function (name) {
              return {
                key: name,
                fn: function ({ value }) {
                  return [
                    _vm.checkIfAnswerValueNeedsDialog(value)
                      ? [
                          _c(
                            "v-btn",
                            {
                              key: `slot-${name}`,
                              attrs: { small: "", text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.showAnswerValueDialog(value)
                                },
                              },
                            },
                            [_vm._v("{ ... }")]
                          ),
                        ]
                      : [_vm._v(" " + _vm._s(value) + " ")],
                  ]
                },
              }
            }),
          ],
          null,
          true
        ),
      }),
      _c("answer-value-dialog", {
        ref: "dialogAnswerValue",
        attrs: { value: _vm.answerValueInDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }